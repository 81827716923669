//
// Datepicker
//

@import "~vue2-datepicker/index.css";

.mx-datepicker {
  width: 100% !important;
  font-family: $font-family-base !important;
}

.mx-input {
  display: block !important;
  height: $input-height !important;
  padding: $input-padding-y $input-padding-x !important;
  font-family: $input-font-family !important;
  @include font-size($input-font-size);
  font-weight: $input-font-weight !important;
  line-height: $input-line-height !important;
  color: $input-color !important;
  background-color: $input-bg !important;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color !important;
  box-shadow: none !important;
}

.mx-calendar {
  font-family: $font-family-base !important;
}

.mx-datepicker-popup {
  box-shadow: $box-shadow-lg !important;
  border: none !important;
  background-color: lighten($dropdown-bg, 2%) !important;
}

.mx-panel-date td,
.mx-panel-date th {
  border-radius: 30px;
}

.mx-calendar-content .cell.disabled,
.inrange {
  background-color: lighten($gray-200, 2%) !important;
}

.mx-calendar-content .cell:hover,
.mx-calendar-content .cell.actived {
  color: $gray-400;  
}

.mx-shortcuts-wrapper .mx-shortcuts {
  color: $gray-500 !important;
}